import { Container } from "react-bootstrap";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import Header from "./components/Header";
import Footer from "./components/Footer";

import HomePage from "./pages/HomePage";
import PostPage from "./pages/PostPage";
import EducationPage from "./pages/EducationPage";
import ResearchPage from "./pages/ResearchPage";
import VideoPage from "./pages/VideoPage";
import LoginPage from "./pages/LoginPage";
import AdminPostList from "./pages/AdminPostList";
import AdminPostEdit from "./pages/AdminPostEdit";
import AdminLinkList from "./pages/AdminLinkList";
import AdminLinkEdit from "./pages/AdminLinkEdit";
import AdminResourceList from "./pages/AdminResourceList";
import AdminResourceEdit from "./pages/AdminResourceEdit";
import ResourcePage from "./pages/ResourcePage";
import AdminVideoList from "./pages/AdminVideoList";
import AdminVideoEdit from "./pages/AdminVideoEdit";


import PrivateRoute from "./utils/PrivateRoute";


function App() {
  
  return (
    <Router>
      <AuthProvider>
        <Header />
        <main className="py-3">
          <Container>
            <Routes>
              <Route path="/" element={<HomePage />} exact />
              <Route path="/education" element={<EducationPage />} exact />
              <Route path="/research" element={<ResearchPage />} exact />
              <Route path="/videos" element={<VideoPage />} exact />
              <Route path="/login" element={<LoginPage />} exact />
              <Route path="/resources" element={<ResourcePage />} exact />

              <Route element={<PrivateRoute />}>
                <Route path="/admin-post-list" element={<AdminPostList />} exact />
                <Route path="/api/posts/edit/:id/" element={<AdminPostEdit />} />
                <Route path="/admin-link-list" element={<AdminLinkList />} exact />
                <Route path="/api/links/edit/:id/" element={<AdminLinkEdit />} />
                <Route path="/admin-resource-list" element={<AdminResourceList />} exact />
                <Route path="/api/resources/edit/:id/" element={<AdminResourceEdit />} />
                <Route path="/admin-video-list" element={<AdminVideoList />} exact />
                <Route path="/api/videos/edit/:id/" element={<AdminVideoEdit />} />
              </Route>

              {/* <Route path='/resources' element={<VideoPage />} exact /> */}
              <Route path="/api/posts/:id/" element={<PostPage />} />

            </Routes>
          </Container>
        </main>
        <Footer />
      </AuthProvider>
    </Router>
  );
}

export default App;
