import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import RecentPost from "../components/RecentPost";
import ReturnButton from "../components/ReturnButton";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function EducationPage() {
  const [posts, setPosts] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const {sendGA} = useContext(AuthContext);

  useEffect(() => {
    sendGA('Education Page');
    async function fetchPosts() {
      const { data } = await axios.get(`${SERVER_URL}/api/posts/`);
      const newData = data.filter(item => {
        return item['category'] === 'Introduction' && JSON.stringify(item['publish']) === 'true' && item['index'].match(/^\d+$/)

      })
      newData.sort((a,b) => a.index - b.index)
      setPosts(newData);
      setIsUpdated(true);
    }
    fetchPosts();
  }, []);
  return (
    <div>
      {isUpdated && (
        <>
          <RecentPost posts={posts} />
          <ReturnButton />
        </>
      )}
    </div>
  );
}

export default EducationPage;
