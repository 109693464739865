import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import parse from "html-react-parser";
import Loader from "../components/Loader";
import ReturnButton from "../components/ReturnButton";
import { LinkContainer } from "react-router-bootstrap";
import AuthContext from "../context/AuthContext";

const styles = {
  cardImage: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
};

function PostPage() {
  const { id } = useParams();
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(true);
  const {sendGA} = useContext(AuthContext);

  useEffect(() => {
    async function fetchPost() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/posts/`
      );

      const publishedData = data.filter((item) => {
        return JSON.stringify(item["publish"]) === "true";
      });

      const newData = publishedData.filter((item) => {
        return JSON.stringify(item["_id"]) === id;
      });
      sendGA(newData[0].title);
      if (newData[0]["category"] === "Research") {
        const sorted_data = publishedData.filter((item) => {
          if (newData[0]["index"].includes(".")) {
            let new_index = newData[0]["index"].split(".", 1);
            return (
              item["index"].includes(`${new_index}.`) &&
              item["index"] !== newData[0]["index"] &&
              item["category"] === "Research"
            );
          } else {
            return (
              item["index"].includes(`${newData[0]["index"]}.`) &&
              item["category"] === "Research"
            );
          }
        });
        sorted_data.sort((a, b) => a.index - b.index);
        setPost(newData[0]);
        setPosts(sorted_data);
      } else if (newData[0]["category"] === "Introduction") {
        const sorted_data = publishedData.filter((item) => {
          if (newData[0]["index"].includes(".")) {
            let new_index = newData[0]["index"].split(".", 1);
            return (
              item["index"].includes(`${new_index}.`) &&
              item["index"] !== newData[0]["index"] &&
              item["category"] === "Introduction"
            );
          } else {
            return (
              item["index"].includes(`${newData[0]["index"]}.`) &&
              item["category"] === "Introduction"
            );
          }
        });
        sorted_data.sort((a, b) => a.index - b.index);
        setPost(newData[0]);
        setPosts(sorted_data);
      }

      setLoading(false);
      window.scrollTo(0, 0);
    }
    fetchPost();
  }, [id]);
  return (
    <>
      {!loading ? (
        <>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12} key={post._id}>
              <div className="card bg-secondary">
                <div className="card-header">{post.title}</div>
                <div className="card-body">
                  <div className="card-text">
                    {parse(`${post.content}`)}
                  </div>
                  <ReturnButton />
                  <Row>
                    {posts.map((post) => (
                      <Col sm={12} md={6} lg={4} xl={4} key={post._id}>
                        <LinkContainer to={`/api/posts/${post._id}/`}>
                          <a className="nav-link" href="/">
                            <div className="m-2 card bg-secondary">
                              <div className="card-header text-center">
                                <i className="fa-regular fa-file-lines"></i>
                                &nbsp;
                                {`${post.index}: ${post.title}`}
                              </div>
                            </div>
                          </a>
                        </LinkContainer>
                      </Col>
                      // <PostCard post = {post} />
                    ))}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          {/* <ReturnButton />
          <Row>
            {posts.map((post) => (
              <Col sm={12} md={6} lg={4} xl={4} key={post._id}>
                <LinkContainer to={`/api/posts/${post._id}/`}>
                  <a className="nav-link" href="/">
                    <div className="m-2 card bg-secondary">
                      <div className="card-header text-center">
                        <i className="fa-regular fa-file-lines"></i>
                        &nbsp;
                        {`${post.index}: ${post.title}`}
                      </div>
                    </div>
                  </a>
                </LinkContainer>
              </Col>
              // <PostCard post = {post} />
            ))}
          </Row> */}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default PostPage;
