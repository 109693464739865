import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const styles = {
  cardImage: {
    objectFit: "contain",
    width: "20vw",
    height: "10vh",
  },
};

function Footer() {
  return (
    <footer>
      <Container>
        {/* <Row>
        <div className="card border-primary mb-3">
      <div className="row text-center">
        <div className="col-md-4 card-body">
          <img
            className="d-block w-100"
            style={styles.cardImage}
            src={require("./images/nsf.png")}
            alt="nsf-logo"
          />
        </div>
        <div className="col-md-8 card-body my-auto">
          <h3>Funded by National Science Foundation</h3>
        </div>
      </div>
    </div>
        </Row> */}
        {/* <Row>
          <Col className="text-center py-3">Copyright &copy; </Col>
        </Row> */}
        <Row>
          {/* <Col className="text-center py-3">Funded by National Science Foundation</Col> */}
          <Col className="text-center py-3">
          Funded by National Science Foundation
          <img
            className="d-block w-100"
            style={styles.cardImage}
            src={require("./images/nsf.png")}
            alt="nsf-logo"
          />
          </Col>

        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
