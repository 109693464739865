import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Navbar, Nav, Container, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext";

function Header() {
  const { user, logoutUser } = useContext(AuthContext);

  const [links, setLinks] = useState([]);

  useEffect(() => {
    const getLinks = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/links/`
        // 'api/links/'
      );
      // console.log(data.length);
      const newLinks = data.filter((link) =>{
        return JSON.stringify(link.publish) === 'true' 
      })
      setLinks(newLinks);
    };
    getLinks();
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark" style={{backgroundColor: '#546E7A'}}>
      {/* #607D8B */}
       {/* bg-primary */}
      <div className="container-fluid">
        <LinkContainer to="/">
          <a className="navbar-brand" href="/">Plant Sphingolipid</a>
        </LinkContainer>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarColor01"
          aria-controls="navbarColor01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarColor01">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <LinkContainer to="/education">
                <a className="nav-link" href="/">
                  Education
                  {/* <span className="visually-hidden">(current)</span> */}
                </a>
              </LinkContainer>
            </li>
            <li className="nav-item">
              <LinkContainer to="/research">
                <a className="nav-link" href="/">
                  Research
                </a>
              </LinkContainer>
            </li>
            <li className="nav-item">
              <LinkContainer to="/videos">
                <a className="nav-link" href="/">
                  Videos
                </a>
              </LinkContainer>
            </li>

            <LinkContainer to="/resources">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Resources
                </a>
              </li>
            </LinkContainer>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                href="/"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Useful Links
              </a>
              <div className="dropdown-menu">
                {links.map((link, index) => (
                  // <> implement divider logic for the last element
                    <a key={link.title} className="dropdown-item" href={link.url} rel="noreferrer" target="_blank" >
                      {link.title}
                      {index < links.length-1 && <div className="dropdown-divider"></div>}
                    </a>
                  // </>
                  
                ))}

                  {/* <div className="dropdown-divider"></div> */}
                  
                {/* <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="/#">
                  Separated link
                </a> */}
              </div>
            </li>
          </ul>
          {!user ? (
            <LinkContainer to="/login">
              <button className="btn btn-secondary my-2 my-sm-0" type="submit">
                Login
              </button>
            </LinkContainer>
          ) : (
            <>
              {/* {user.isAdmin && ( */}
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Admin Panel
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <LinkContainer to="/admin-post-list">
                    <a className="dropdown-item" href="/">Post</a>
                  </LinkContainer>
                  {/* <a className="dropdown-item" href="#">User</a> */}
                  <div className="dropdown-divider"></div>
                  <LinkContainer to="/admin-link-list">
                    <a className="dropdown-item" href="/">Link</a>
                  </LinkContainer>

                  <div className="dropdown-divider"></div>
                  <LinkContainer to="/admin-resource-list">
                    <a className="dropdown-item" href="/">Resource</a>
                  </LinkContainer>

                  <div className="dropdown-divider"></div>
                  <LinkContainer to="/admin-video-list">
                    <a className="dropdown-item" href="/">Video</a>
                  </LinkContainer>
                  {/* <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#">Something else here</a> */}
                </div>
              </div>
              {/* )} */}
              <span>&nbsp; &nbsp;</span>
              <LinkContainer to="/login">
                <button
                  className="btn btn-secondary my-2 my-sm-0"
                  onClick={logoutUser}
                >
                  Logout
                </button>
              </LinkContainer>
            </>
          )}
          {/* <form className="d-flex">
            <input
              className="form-control me-sm-2"
              type="text"
              placeholder="Search"
            />
            <button className="btn btn-secondary my-2 my-sm-0" type="submit">
              Search
            </button>
          </form> */}
        </div>
      </div>
    </nav>
  );
}

export default Header;
