import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import AuthContext from "../context/AuthContext";
import CustomModal from "../components/CustomModal";
import Loader from "../components/Loader";

const styles = {
  cardImage: {
    objectFit: "contain",
    width: "50vw",
    height: "15vh",
  },
};

function AdminLinkEdit() {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [resource, setResource] = useState({
    title: "",
    file: "",
    description: "",
  });

  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const changeHandler = async (e) => {
    let newData = { ...resource };
    if(e.target.name === 'publish'){
      newData[e.target.name] = !resource.publish
      setResource(newData);
    }else{
      newData[e.target.name] = e.target.value;
      setResource(newData);
    }

  };

  const fileHandler = async (e) => {
    const file = e.target.files[0];
    setFile(file);

  };

  const submitHandler = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("title", resource.title);
    formData.append("description", resource.description);
    if (file != null) {
        formData.append("file", file, file.name);
      } else {
        formData.append("file", file);
      }
    formData.append('publish', resource.publish ? 'True' : 'False');

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/resources/edit/${id}/`,
      formData,
      config
    );
  };

  useEffect(() => {
    async function fetchResource() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/resources/${id}/`
      );
      setResource(data);
      setLoading(false);
    }
    fetchResource();
  }, []);

  return (
    <>
      {!loading ? (
        <Row className="justify-content-md-center my-4">
          {/* sm={12} md={12} lg={12} xl={10}  */}

          <Col xs={12} md={8}>
            <div className="m-3 card bg-secondary">
              <div className="card-header">Resource Edit Form</div>
              <div className="card-body">
                <form onSubmit={submitHandler} encType="multipart/form-data">
                  <fieldset>
                    <div className="form-group row"></div>
                    <div className="form-group">
                      <label htmlFor="title" className="form-label mt-4">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        // aria-describedby="titleHelp"
                        // placeholder="Enter email"
                        defaultValue={resource.title}
                        onChange={changeHandler}
                        // contentEditable
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="file" className="form-label mt-4">
                        Upload File
                      </label>{" "}
                      <br />
                      <input
                        type="file"
                        className="form-file"
                        name="file"
                        onChange={fileHandler}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="description" className="form-label mt-4">
                        Description
                      </label>

                      <textarea
                        className="form-control"
                        name="description"
                        placeholder="content"
                        defaultValue={resource.description}
                        onChange={changeHandler}
                        // contentEditable
                      />

                      {/* <ReactQuill
                        // theme="snow"
                        // name="content"
                        className="form-control"
                        defaultValue={post.content}
                        onChange={quillHandler}
                      /> */}
                    </div>

                    <br />
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="publish"
                        // placeholder="content"
                        // defaultValue={link.publish}
                        // contentEditable
                        checked={resource.publish}
                        onChange={changeHandler}
                      />

                      <label htmlFor="publish" className="form-check-label">
                        Publish
                      </label>
                    </div>

                    <br />
                    {/* {loading ?
                    (<div className="d-grid gap-2">
                      <button className="btn btn-lg btn-secondary" type="submit" disabled>
                        Submit
                      </button>
                    </div>)
                    :
                    (<div className="d-grid gap-2">
                    <button className="btn btn-lg btn-secondary" type="submit">
                      Submit
                    </button>
                  </div>)} */}
                    <div className="d-grid gap-2">
                      <button
                        className="btn btn-lg btn-secondary"
                        type="submit"
                        variant="dark"
                      >
                        Submit
                      </button>
                      <LinkContainer to="/admin-resource-list">
                        <Button className="btn btn-lg btn-secondary">
                          {/* <i className="fas fa-edit"></i> */}Back to Resources
                        </Button>
                      </LinkContainer>
                    </div>
                  </fieldset>
                </form>
                <br />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default AdminLinkEdit;
