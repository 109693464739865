import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import AuthContext from "../context/AuthContext";
import CustomModal from "../components/CustomModal";
import Loader from "../components/Loader";

const styles = {
  cardImage: {
    objectFit: "contain",
    width: "50vw",
    height: "15vh",
  },
};

function AdminLinkEdit() {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [link, setLink] = useState({
    title: "",
    url: "",
    publish: "",
  });

  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);

  const changeHandler = async (e) => {
    let newData = { ...link };
    if(e.target.name === 'publish'){
      newData[e.target.name] = !link.publish
      setLink(newData);
    }else{
      newData[e.target.name] = e.target.value;
      setLink(newData);
    }

  };

  const submitHandler = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("title", link.title);
    if (link.url.includes("http://") || link.url.includes("https://")) {
      formData.append("url", link.url);
    } else {
      formData.append("url", `http://${link.url}`);
    }
    formData.append('publish', link.publish ? 'True' : 'False');

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/links/edit/${id}/`,
      formData,
      config
    );
  };

  useEffect(() => {
    async function fetchLink() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/links/${id}/`
      );
      setLink(data);
      setLoading(false);
    }
    fetchLink();
  }, []);

  return (
    <>
      {!loading ? (
        <Row className="justify-content-md-center my-4">
          {/* sm={12} md={12} lg={12} xl={10}  */}

          <Col xs={12} md={12}>
            <div className="m-3 card bg-secondary">
              <div className="card-header">Link Edit Form</div>
              <div className="card-body">
                <form onSubmit={submitHandler} encType="multipart/form-data">
                  <fieldset>
                    <div className="form-group row"></div>
                    <div className="form-group">
                      <label htmlFor="title" className="form-label mt-4">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        // aria-describedby="titleHelp"
                        // placeholder="Enter email"
                        defaultValue={link.title}
                        onChange={changeHandler}
                        // contentEditable
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="url" className="form-label mt-4">
                        URL
                      </label>

                      <textarea
                        className="form-control"
                        name="url"
                        placeholder="content"
                        defaultValue={link.url}
                        onChange={changeHandler}
                        // contentEditable
                      />
                    </div>
                    <br />
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="publish"
                        // placeholder="content"
                        // defaultValue={link.publish}
                        // contentEditable
                        checked={link.publish}
                        onChange={changeHandler}
                      />

                      <label htmlFor="publish" className="form-check-label">
                        Publish
                      </label>
                    </div>
                    <br />
                    <div className="d-grid gap-2">
                      <button
                        className="btn btn-lg btn-secondary"
                        type="submit"
                        variant="dark"
                      >
                        Submit
                      </button>
                      <LinkContainer to="/admin-link-list">
                        <Button className="btn btn-lg btn-secondary">
                          {/* <i className="fas fa-edit"></i> */}Back to Links
                        </Button>
                      </LinkContainer>
                    </div>
                  </fieldset>
                </form>
                <br />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default AdminLinkEdit;
