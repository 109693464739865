import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import VideoCard from "../components/VideoCard";
import { Row, Col } from "react-bootstrap";
import ReturnButton from "../components/ReturnButton";
import AuthContext from "../context/AuthContext";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function VideoPage() {
  const [videos, setVideos] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const {sendGA} = useContext(AuthContext);

  useEffect(() => {
    // window.gtag("event", "page_view", {
    //   page_title: 'Video Page',
    //   page_location: window.location.href,
    //   page_path: window.location.pathname+window.location.hash,
    //   send_to: 'G-3ENSV9NWQN'
    // });
    sendGA('Video Page');
    async function fetchVideos() {
      const { data } = await axios.get(`${SERVER_URL}/api/videos/`);
      const newData = data.filter((video) =>{
        return JSON.stringify(video.publish) === 'true' 
      })
      setVideos(newData);
      setIsUpdated(true);
    }
    fetchVideos();
  }, []);
  return (
    <div>
      {isUpdated && (
        <Row>
          {videos.map((video) => (
            <Col sm={12} md={6} lg={4} xl={4} key={video._id}>
              <VideoCard video={video} />
            </Col>
          ))}
          <ReturnButton />
        </Row>
        
      )}
    </div>
  );
}

export default VideoPage;
