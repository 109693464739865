import React from "react";
import parse from "html-react-parser";
import { LinkContainer } from "react-router-bootstrap";

const styles = {
  cardImage: {
    objectFit: "cover",
    width: "50vw",
    height: "20vh",
  },
};

function PostCard({ post }) {
  return (
    <LinkContainer to={`/api/posts/${post._id}/`}>
      <a className="nav-link" href="/">
        <div className="m-3 card bg-secondary">
          {/* <div className="card-header">{post.title}</div> */}
          <div className="card-body">
            {/* <h4 className="card-title">{post.subtitle}</h4> */}
            <img
              className="my-3 d-block w-100"
              style={styles.cardImage}
              src={`${process.env.REACT_APP_SERVER_URL + post.image}`}
              alt={post.title}
            />
            {/* <div className="card-text">
              {parse(`${(post.content).substring(0,100)}`)}Read More
            </div> */}
            <div className="card-text text-center">{`${post.index}: ${post.title}`}</div>
            {/* <Link to={`/api/posts/${post._id}/`}>Read More</Link> */}
          </div>
        </div>
      </a>
    </LinkContainer>
  );
}

export default PostCard;
