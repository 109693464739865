import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import RecentPost from "../components/RecentPost";
import ReturnButton from "../components/ReturnButton";
import AuthContext from "../context/AuthContext";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ResearchPage() {
  const [posts, setPosts] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const {sendGA} = useContext(AuthContext);

  useEffect(() => {
    // window.gtag("event", "page_view", {
    //   page_title: 'Research Page',
    //   page_location: window.location.href,
    //   page_path: window.location.pathname+window.location.hash,
    //   send_to: 'G-3ENSV9NWQN'
    // });
    sendGA('Research Page');
    async function fetchPosts() {
      const { data } = await axios.get(`${SERVER_URL}/api/posts/`);
      const newData = data.filter(item => {
        return item['category'] === 'Research' && JSON.stringify(item['publish']) === 'true' && item['index'].match(/^\d+$/)

      })
      newData.sort((a,b) => a.index - b.index)
      setPosts(newData);
      setIsUpdated(true);
    }
    fetchPosts();
  }, []);
  return (
    <div>
      {isUpdated && (
        <>
          <RecentPost posts={posts} />
          <ReturnButton />
        </>
      )}
    </div>
  );
}

export default ResearchPage;
