import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <center>
      <Spinner animation="grow" variant="primary" />
      <Spinner animation="grow" variant="primary" />
      <Spinner animation="grow" variant="primary" />
    </center>
  );
};

export default Loader;
