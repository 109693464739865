import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image, ListGroup, Button, Card } from "react-bootstrap";
import AuthContext from "../context/AuthContext";

function LoginPage() {
  let { loginUser, user, loginError, loading } = useContext(AuthContext);
  const [error, setError] = useState(loginError);

  return (
    <Row className="justify-content-md-center my-4">
      {/* sm={12} md={12} lg={12} xl={10}  */}

      <Col xs={12} md={6}>
      {loginError &&
        <div className="alert alert-dismissible alert-danger text-center">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
          ></button>
          Wrong Credentials! Please Try Again.
        </div>
      }
        <div className="m-3 card bg-secondary">
          <div className="card-header">Login Form</div>
          <div className="card-body">
            <form onSubmit={loginUser}>
              <fieldset>
                <div className="form-group row"></div>
                <div className="form-group">
                  <label htmlFor="email" className="form-label mt-4">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="form-label mt-4">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    required
                  />
                  <br />
                  {loading ?
                  (<div className="d-grid gap-2">
                    <button className="btn btn-lg btn-secondary" type="submit" disabled>
                      Submit
                    </button>
                  </div>)
                  :
                  (<div className="d-grid gap-2">
                  <button className="btn btn-lg btn-secondary" type="submit">
                    Submit
                  </button>
                </div>)}
                </div>
              </fieldset>
            </form>
            <br />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default LoginPage;
