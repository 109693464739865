import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Button,
  Card,
  Table,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import CustomModal from "../components/CustomModal";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import Loader from "../components/Loader";
// import { ImageResize } from 'quill-image-resize-module';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function AdminPostPage() {
  const [id, setId] = useState();
  const [posts, setPosts] = useState([]);
  const [education, setEducation] = useState([]);
  const [research, setResearch] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [modalshow, setModalShow] = useState(false);
  const [deletepost, setDeletePost] = useState(false);
  const { user, fetchPosts } = useContext(AuthContext);
  const navigate = useNavigate();

  const addPostHandler = async () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user}`,
      },
    };
    const { data } = await axios.post(
      `${SERVER_URL}/api/posts/create/`,
      {},
      config
    );
    navigate(`/api/posts/edit/${data._id}/`);
  };

  const deleteHandler = async (id) => {
    setId(id);
    let delPost = posts.filter((post) => {
      return post._id === id;
    });

    if (delPost[0]["category"] == "Introduction") {
      const newPost = education.filter((post) => {
        return post._id !== id;
      });
      setEducation(newPost);
    } else if (delPost[0]["category"] == "Research") {
      const newPost = research.filter((post) => {
        return post._id !== id;
      });
      setResearch(newPost);
    }

    const { data } = await axios.delete(
      `${SERVER_URL}/api/posts/delete/${id}/`
    );
    // setModalShow(!modalshow);
  };


  useEffect(() => {
    async function fetchPosts() {
      const { data } = await axios.get(`${SERVER_URL}/api/posts/`);
      const eduData = data.filter((item) => {
        return item["category"] === "Introduction";
      });
      eduData.sort((a, b) => a.index - b.index);
      setEducation(eduData);

      const researchData = data.filter((item) => {
        return item["category"] === "Research";
      });
      researchData.sort((a, b) => a.index - b.index);
      setResearch(researchData);
      data.sort((a, b) => a.index - b.index);
      setPosts(data);
      setIsUpdated(true);
    }
    fetchPosts();
  }, []);
  return (
    <div>
      {modalshow && (
        <CustomModal
          modalShow={modalshow}
          setModalShow={setModalShow}
          deletepost={deletepost}
          setDeletePost={setDeletePost}
          message={"Are you sure you want to delete this?"}
        />
      )}
      <Row className="align-items-center">
        <Col>
          <h1>Posts</h1>
        </Col>
        <Col className="text-end">
          <Button className="my-3" onClick={addPostHandler}>
            <i className="fas fa-plus"></i>&nbsp;&nbsp;Create Post
          </Button>
        </Col>
      </Row>
      {!isUpdated ? (
        <Loader />
      ) : (
        <>
          <h4>Education</h4>
          <Table striped bordered hover responsive className="table-sm my-4">
            <thead className="text-center">
              <tr>
                {/* <th>ID</th> */}
                <th>Index</th>
                <th>Title</th>
                <th>Category</th>
                {/* <th>Content</th> */}
                <th>Published</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {education.map((post) => (
                <tr key={post._id}>
                  {/* <td>{post._id}</td> */}
                  <td>{post.index}</td>
                  <td>{post.title}</td>
                  <td>{post.category}</td>
                  {/* <td>{parse(`${post.content.substring(0, 20)}`)}</td> */}
                  <td>{post.publish ? "Yes" : "No"}</td>
                  <td className="text-center">
                    <LinkContainer to={`/api/posts/edit/${post._id}/`}>
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    &nbsp;&nbsp;
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(post._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h4>Research</h4>
          <Table striped bordered hover responsive className="table-sm my-4">
            <thead className="text-center">
              <tr>
                {/* <th>ID</th> */}
                <th>Index</th>
                <th>Title</th>
                <th>Category</th>
                {/* <th>Content</th> */}
                <th>Published</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {research.map((post) => (
                <tr key={post._id}>
                  {/* <td>{post._id}</td> */}
                  <td>{post.index}</td>
                  <td>{post.title}</td>
                  <td>{post.category}</td>
                  {/* <td>{parse(`${post.content.substring(0, 20)}`)}</td> */}
                  <td>{post.publish ? "Yes" : "No"}</td>
                  <td className="text-center">
                    <LinkContainer to={`/api/posts/edit/${post._id}/`}>
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    &nbsp;&nbsp;
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(post._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
}

export default AdminPostPage;
