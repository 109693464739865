import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import CustomModal from "../components/CustomModal";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function AdminResourceList() {
  const [resources, setResources] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const addResourceHandler = async () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user}`,
      },
    };
    const { data } = await axios.post(
      `${SERVER_URL}/api/resources/create/`,
      {},
      config
    );
    navigate(`/api/resources/edit/${data._id}/`);
  };

  const deleteHandler = async (id) => {

    const newData = resources.filter((resource) => {
      return resource._id !== id;
    });
    setResources(newData);
    const { data } = await axios.delete(
      `${SERVER_URL}/api/resources/delete/${id}/`
    );
  };

  useEffect(() => {
    const getResources = async () => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/resources/`
        );
        setResources(data);
        setIsUpdated(true);
      };
      getResources();
  }, []);
  return (
    <div>
      <Row className="align-items-center">
        <Col>
          <h1>Resources</h1>
        </Col>
        <Col className="text-end">
          <Button className="my-3" onClick={addResourceHandler}>
            <i className="fas fa-plus"></i>&nbsp;&nbsp;Create Resource
          </Button>
        </Col>
      </Row>
      {!isUpdated ? (
        <Loader />
      ) : (
        <Table striped bordered hover responsive className="table-sm my-4">
          <thead className="text-center">
            <tr>
              {/* <th>ID</th> */}
              <th>Title</th>
              <th>File</th>
              <th>Description</th>
              <th>Published</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {resources.map((resource) => (
              <tr key={resource._id} className="text-center">
                {/* <td>{resource._id}</td> */}
                <td>{resource.title}</td>
                <td>{resource.file ? (resource.file).replace('/images/files/','') : ''}</td>
                <td>{resource.description}</td>
                <td>{resource.publish ? "Yes" : "No"}</td>
                {/* <td>{JSON.stringify(post.publish)}</td> */}
                <td className="text-center">
                  <LinkContainer to={`/api/resources/edit/${resource._id}/`}>
                    <Button variant="light" className="btn-sm">
                      <i className="fas fa-edit"></i>
                    </Button>
                  </LinkContainer>
                  &nbsp;&nbsp;
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(resource._id)}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default AdminResourceList;
