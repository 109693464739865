import React from "react";

const styles = {
  cardImage: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
};

function VideoCard({ video }) {
  return (

    <div className="m-2 card bg-secondary">
      <div className="card-header">{video.title}</div>
      <div className="card-body">
        {/* <h4 className="card-title">{post.subtitle}</h4> */}
        <iframe
          src= {video.url}
          style={styles.cardImage}
        //   frameborder='0'
        //   allow='autoplay; encrypted-media'
          // height="200"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          title='video'
        />
      </div>
    </div>
  );
}

export default VideoCard;
