import React,{useState, useEffect} from "react";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import PostCard from "./PostCard";

function RecentPost({posts}) {
  // const [rposts, setRposts] = useState([]);

  // useEffect(()=>{
  //   const recent_post = []
  //   posts.map((post) => {
  //     if(post['editorsPick'] == false){
  //       recent_post.push(post);
  //     }
  //   });
  //   setRposts(recent_post);
  //   console.log(recent_post);
  // },[])
  return (
    <Row>
        {/* <h3>Recent Works</h3> */}
      {posts.map((post) => (
        <Col sm={12} md={6} lg={4} xl={4}  key={post._id}>
            <PostCard post = {post} />
        </Col>
      ))}
    </Row>
  );
}

export default RecentPost;
