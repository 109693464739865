import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Editor from '../components/Editor';
// import Editor2 from "../components/Editor2";
// import ReactQuill, {Quill} from "react-quill";
// import { useQuill } from 'react-quilljs';
// import Quill from 'quill';
// import ImageResize from 'quill-image-resize-module-react';

// import "react-quill/dist/quill.snow.css";
// import "react-quill/dist/quill.core.css";
// import 'react-quill/dist/quill.bubble.css';
import { LinkContainer } from "react-router-bootstrap";
import AuthContext from "../context/AuthContext";
import CustomModal from "../components/CustomModal";
import Loader from "../components/Loader";


// Quill.register('modules/imageResize', ImageResize);

const styles = {
  cardImage: {
    objectFit: "contain",
    width: "50vw",
    height: "15vh",
  },
};

// const modules = {
//   toolbar: [
//     [{ 'header': [1, 2, false] }],
//     ['bold', 'italic', 'underline','strike', 'blockquote'],
//     [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
//     // [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
//     // [{ align: ["right", "center", "justify"] }],

//     ['link', 'image'],
//     ['clean']
//   ],
// },

// formats = [
//   'header',
//   'bold', 'italic', 'underline', 'strike', 'blockquote',
//   'list', 'bullet', 'indent',
//   // 'align',
//   'link', 'image'
// ]



function AdminPostEdit() {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [post, setPost] = useState({
    index:0,
    title: "",
    category: "",
    image: null,
    // content: "",
    publish: "",
  });
  const [content, setContent] = useState();

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const categories = ["Introduction", "Research"];

  const changeHandler = async (e) => {
    let newData = { ...post };
    if (e.target.name === "publish") {
      newData[e.target.name] = !post.publish;
      setPost(newData);
    } else {
      newData[e.target.name] = e.target.value;
      setPost(newData);
    }
  };

  const quillHandler = async (value) => {
    let newData = { ...post };
    newData['content'] = value;
    setPost(newData);
  };


  const imageHandler = async (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("index", post.index);
    formData.append("title", post.title);
    formData.append("category", post.category);
    formData.append("content", content);
    if (image != null) {
      formData.append("image", image, image.name);
    } else {
      formData.append("image", image);
    }
    formData.append("publish", post.publish ? "True" : "False");

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/posts/edit/${id}/`,
      formData,
      config
    );
  };

  useEffect(() => {
    async function fetchPost() {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/posts/${id}/`
      );
      setContent(data.content);
      setPost(data);
      setLoading(false);
    }
    fetchPost();
  }, []);

  return (
    <>
      {!loading ? (
        <Row className="justify-content-md-center my-4">
          {/* sm={12} md={12} lg={12} xl={10}  */}

          <Col xs={12} md={12}>
            <div className="m-3 card bg-secondary">
              <div className="card-header">Post Edit Form</div>
              <div className="card-body">
                <form onSubmit={submitHandler} encType="multipart/form-data">
                  <fieldset>
                    <div className="form-group row"></div>

                    <div className="form-group">
                      <label htmlFor="index" className="form-label mt-4">
                        Index
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="index"
                        // aria-describedby="titleHelp"
                        // placeholder="Enter email"
                        defaultValue={post.index}
                        onChange={changeHandler}
                        // contentEditable
                      />
                    </div>


                    <div className="form-group">
                      <label htmlFor="title" className="form-label mt-4">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        // aria-describedby="titleHelp"
                        // placeholder="Enter email"
                        defaultValue={post.title}
                        onChange={changeHandler}
                        // contentEditable
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="image" className="form-label mt-4">
                        Upload Image
                      </label>{" "}
                      <br />
                      <input
                        type="file"
                        className="form-file"
                        name="image"
                        onChange={imageHandler}
                      />
                    </div>

                    <img
                      className="my-3 d-block w-100"
                      style={styles.cardImage}
                      src={`${process.env.REACT_APP_SERVER_URL + post.image}`}
                      alt={post.image}
                    />

                    <div className="form-group">
                      <label htmlFor="category" className="form-label mt-4">
                        Category
                      </label>
                      <select
                        name="category"
                        className="form-select"
                        // value={category}
                        defaultValue={post.category}
                        onChange={changeHandler}
                      >
                        {/* <option selected>Open this select menu</option> */}
                        {categories.map((category) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      </select>
                    </div>
                    
                    <div className="form-group">
                      <label htmlFor="content" className="form-label mt-4">
                        Content
                      </label>

                      <Editor
                        // theme="snow"
                        // name="content"
                        // modules={modules}
                        // formats={formats}
                        className="form-control"
                        defaultValue={post.content}
                        // defaultValue = {quill.clipboard.dangerouslyPasteHTML(post.content)}
                        // onChange={quillHandler}
                        content = {content}
                        setContent = {setContent}
                      />
                    </div>
                    <br />
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="publish"
                        // placeholder="content"
                        // defaultValue={link.publish}
                        // contentEditable
                        checked={post.publish}
                        onChange={changeHandler}
                      />

                      <label htmlFor="publish" className="form-check-label">
                        Publish
                      </label>
                    </div>
                    <br />
                    <div className="d-grid gap-2">
                      <button
                        className="btn btn-lg btn-secondary"
                        type="submit"
                        variant="dark"
                      >
                        Submit
                      </button>
                      <LinkContainer to="/admin-post-list">
                        <Button className="btn btn-lg btn-secondary">
                          {/* <i className="fas fa-edit"></i> */}Back to Posts
                        </Button>
                      </LinkContainer>
                    </div>
                  </fieldset>
                </form>
                <br />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default AdminPostEdit;
