import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Table,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function AdminVideoList() {
  const [videos, setVideos] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const addVideoHandler = async () => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user}`,
      },
    };
    const { data } = await axios.post(
      `${SERVER_URL}/api/videos/create/`,
      {},
      config
    );
    navigate(`/api/videos/edit/${data._id}/`);
  };

  const deleteHandler = async (id) => {

    const newVideos = videos.filter((video) => {
      return video._id !== id;
    });
    setVideos(newVideos);
    const { data } = await axios.delete(
      `${SERVER_URL}/api/videos/delete/${id}/`
    );
  };

  useEffect(() => {
    const getVideos = async () => {
        const { data } = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/videos/`
        );
        setVideos(data);
        setIsUpdated(true);
      };
      getVideos();
  }, []);
  return (
    <div>
      <Row className="align-items-center">
        <Col>
          <h1>Videos</h1>
        </Col>
        <Col className="text-end">
          <Button className="my-3" onClick={addVideoHandler}>
            <i className="fas fa-plus"></i>&nbsp;&nbsp;Create Video
          </Button>
        </Col>
      </Row>
      {!isUpdated ? (
        <Loader />
      ) : (
        <Table striped bordered hover responsive className="table-sm my-4">
          <thead className="text-center">
            <tr>
              {/* <th>ID</th> */}
              <th>Title</th>
              <th>URL</th>
              <th>Published</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {videos.map((video) => (
              <tr key={video._id} className="text-center">
                {/* <td>{link._id}</td> */}
                <td>{video.title}</td>
                <td>{video.url}</td>
                <td>{video.publish ? "Yes" : "No"}</td>
                {/* <td>{JSON.stringify(post.publish)}</td> */}
                <td className="text-center">
                  <LinkContainer to={`/api/videos/edit/${video._id}/`}>
                    <Button variant="light" className="btn-sm">
                      <i className="fas fa-edit"></i>
                    </Button>
                  </LinkContainer>
                  &nbsp;&nbsp;
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(video._id)}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default AdminVideoList;
