import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import HomeCarousel from "../components/HomeCarousel";
import IntroPost from "../components/IntroPost";
import RecentPost from "../components/RecentPost";
import AuthContext from "../context/AuthContext";
import { Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function HomePage() {
  const [posts, setPosts] = useState([]);
  const [isUpdated, setIsUpdated] = useState(true);
  const {sendGA} = useContext(AuthContext);

  useEffect(() => {
    sendGA('Home Page');
    async function fetchPosts() {
      const { data } = await axios.get(`${SERVER_URL}/api/posts/`);
      const newData = data.filter((item) => {
        return (
          item["category"] === "Introduction" &&
          JSON.stringify(item["publish"]) === "true"
        );
        // && JSON.stringify(item['publish']) === 'true'
      });
      setPosts(newData);
      setIsUpdated(true);
    }
    // fetchPosts();
  }, []);
  return (
    <div>
      {/* <HomeCarousel /> */}
      <br />
      <div className="mt-2 col-md-12"></div>
      {isUpdated && (
        <>
          {/* <IntroPost posts={posts} />
          <RecentPost posts={posts} /> */}

          <Row className="text-center">
            <Col sm={12} md={6} lg={4} xl={3}>
              <LinkContainer to="/education">
                <a className="nav-link" href="/">
                  <div className="card bg-secondary mb-3">
                    <img
                      className="card-img-top"
                      src={require("./test_image.jpg")}
                      alt="Card image cap"
                    />
                    {/* style={{backgroundColor: 'rgba(245, 245, 245, 0.9)'}} */}
                    <div className="card-body">
                      <h4 className="card-title">Education</h4>
                    </div>
                  </div>
                </a>
              </LinkContainer>
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <LinkContainer to="/research">
                <a className="nav-link" href="/">
                  <div className="card bg-secondary mb-3">
                    <img
                      className="card-img-top"
                      src={require("./test_image.jpg")}
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h4 className="card-title">Research</h4>
                    </div>
                  </div>
                </a>
              </LinkContainer>
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <LinkContainer to="/videos">
                <a className="nav-link" href="/">
                  <div className="card bg-secondary mb-3">
                    <img
                      className="card-img-top"
                      src={require("./test_image.jpg")}
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h4 className="card-title">Videos</h4>
                    </div>
                  </div>
                </a>
              </LinkContainer>
            </Col>

            <Col sm={12} md={6} lg={4} xl={3}>
              <LinkContainer to="/resources">
                <a className="nav-link" href="/">
                  <div className="card bg-secondary mb-3">
                    <img
                      className="card-img-top"
                      src={require("./test_image.jpg")}
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h4 className="card-title">Resource</h4>
                    </div>
                  </div>
                </a>
              </LinkContainer>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default HomePage;
