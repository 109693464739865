import { useEffect } from "react";
import { useQuill } from "react-quilljs";
import BlotFormatter from "quill-blot-formatter";
import ResizeAction from 'quill-blot-formatter/dist/actions/ResizeAction';
import ImageSpec from 'quill-blot-formatter/dist/specs/ImageSpec';
import DeleteAction from 'quill-blot-formatter/dist/actions/DeleteAction';
import "quill/dist/quill.snow.css";

// import './styles.css';

class CustomImageSpec extends ImageSpec {
  getActions() {
      return [ResizeAction, DeleteAction];
  }
}


const Editor = ({ content, setContent }) => {
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      blotFormatter: {
        specs:[
          CustomImageSpec
        ],
      },
      toolbar: [
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ font: [] }, { header: [1, 2, 3, 4] }],
        ["bold", "italic", "underline", "strike"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
    },
    formats: [
      "size",
      "font",
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "list",
      "bullet",
      "indent",
      "align",
      "link",
      "image",
      "video"
    ],
  });

  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register("modules/blotFormatter", BlotFormatter);
  }

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(content);
      quill.on("text-change", (delta, oldContents) => {
        // console.log(delta);
        // let currrentContents = quill.getContents();

        setContent(quill.root.innerHTML);
        // console.log(currrentContents.diff(oldContents));
      });
    }
  }, [quill, Quill]);

  return (
    <div>
      <div ref={quillRef} />
    </div>
  );
};

export default Editor;
