import Carousel from "react-bootstrap/Carousel";
import { Row, Col } from "react-bootstrap";

const styles = {
  cardImage: {
    objectFit: "contain",
    width: "50vw",
    height: "20vh",
  },
};

function HomeCarousel() {
  return (
    //  <Carousel>
    //   <Carousel.Item>
    //     <img
    //       className="d-block w-100"
    //       src={require("./images/dark.png")}
    //       alt="First slide"
    //       height={200}
    //     />
    //     <Carousel.Caption>
    //       <h3>First slide Label</h3>
    //       <p>First slide Subtitle</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <img
    //       className="d-block w-100"
    //       src={require("./images/dark.png")}
    //       alt="Second slide"
    //     />

    //     <Carousel.Caption>
    //       <h3>Second slide label</h3>
    //       <p>Second slide Subtitle</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <img
    //       className="d-block w-100"
    //       src={require("./images/dark.png")}
    //       alt="Third slide"
    //     />

    //     <Carousel.Caption>
    //       <h3>Third slide label</h3>
    //       <p>
    //       Third slide Subtitle
    //       </p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    // </Carousel>

    <div className="card border-primary mb-3">
      <div className="row text-center">
        {/* <div className="col-md-4 card-body">
          <img
            className="d-block w-100"
            style={styles.cardImage}
            src={require("./images/nsf.png")}
            alt="nsf-logo"
          />
        </div> */}
        <div className="col-md-8 card-body my-auto">
          <h1>Plant Sphingolipid</h1>
        </div>
      </div>
    </div>

    // <Row className="m-4">
    //     <Col sm={12} md={6} lg={4} xl={4}>
    //       Test
    //     </Col>
    // </Row>
  );
}

export default HomeCarousel;
