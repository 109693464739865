import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

function ReturnButton() {
  const navigate = useNavigate();
  return (
    <>
      <Row className="justify-content-md-center my-4 text-left">
        <Col xs={12} md={12}>
          <button
            className="btn btn-lg btn-secondary mx-3"
            type="submit"
            variant="dark"
            onClick={() => navigate(-1)}
          >
            {/* <i class="fa-sharp fa-solid fa-left-long"></i> */}
            Return
          </button>
        </Col>
      </Row>
    </>
  );
}

export default ReturnButton;
