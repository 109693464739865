import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [links, setLinks] = useState([]);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

   const fetchPosts = async () => {
    const { data } = await axios.get(`${SERVER_URL}/api/posts/`);
    setPosts(data);
    const categories = [...new Set(posts.map(post => post.category))];
  }

  const loginUser = async (e) => {
    setLoading(true);

    e.preventDefault();
    const { data } = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/users/loginUser/`,
      { email: e.target.email.value, password: e.target.password.value }
    );
    setLoading(false);

    if (data === "Invalid Credentials!") {
        setLoginError(true);
    } else {
      setUser(data.token);
      setLoginError(false);
      localStorage.setItem('user', JSON.stringify(data.token));
      navigate("/");
    }
  };

  const logoutUser = () => {
    setUser(null);
    setLoginError(false);
    localStorage.removeItem('user');
    navigate("/login");
  }


  const sendGA = (pageTitle) => {
    window.gtag("event", "page_view", {
      page_title: pageTitle,
      page_location: window.location.href,
      page_path: window.location.pathname+window.location.hash,
      send_to: 'G-3ENSV9NWQN'
    });
  }


  // getResources();

  let contextData = {
    // fetchPosts: fetchPosts,
    // posts: posts,
    // links: links,
    // getLinks: getLinks,
    loginUser: loginUser,
    logoutUser: logoutUser,
    user: user,
    loginError: loginError,
    sendGA: sendGA,
    // loading: loading
  };
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
